import React, { Component, useState } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { isAnyFilterActive, isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initiateOrder } from '../../containers/ListingPage/ListingPage.duck';

import { sendInvite } from '../../ducks/user.duck';
import {
  Footer,
  Page,
  TabNav,
  GoBackLink,
  NotificationBadge,
  Modal,
  SideNavigation,
  AddUserModal,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import SearchResultsPanelWithHeaders from './SearchResultsPanel/SearchResultsPanelWithHeaders';
import ActiveOrder from './ActiveOrder/ActiveOrder';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const validUrlQueryParamsFromProps = props => {
  const { location, filterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  return validURLParamsForExtendedData(searchInURL, filterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isInviteSupplierModalOpen: false,
      isInviteBuyerModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.onOpenSupplierModal = this.onOpenSupplierModal.bind(this);

    this.onCloseSupplierModal = this.onCloseSupplierModal.bind(this);

    this.onOpenBuyerModal = this.onOpenBuyerModal.bind(this);
    this.onCloseBuyerModal = this.onCloseBuyerModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      currentUserActiveBaskets,
      currentUserActiveBasketsDetails,
      lineItems,
    } = this.props;
    const { quantity: quantityRaw, ...otherOrderData } = values;

    const listingId = new UUID(values.listingId);
    const listing = getListing(listingId);

    const initialValues = {
      listing,
      orderData: {
        stockReservationQuantity: Number.parseInt(quantityRaw, 10),
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const { dispatch, onInitiateOrder } = this.props;

    const requestParams = {
      listingId: listing.id,
      quantity: Number.parseInt(quantityRaw, 10),
      lineItems: lineItems,
    };

    const transactionIdMaybe = !!currentUserActiveBaskets[listing.author.id.uuid]
      ? currentUserActiveBaskets[listing.author.id.uuid]
      : null;

    onInitiateOrder(requestParams, transactionIdMaybe).then(params => {
      const routes = routeConfiguration();
      const listingPagePath = pathByRouteName('SearchPage', routes, {
        id: params.id.uuid,
      });
      history.push(listingPagePath + this.props.location.search + '&addedtoorder=true');
    });
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenSupplierModal() {
    this.setState({ isInviteSupplierModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseSupplierModal() {
    this.setState({ isInviteSupplierModalOpen: false });
  }

  onOpenBuyerModal() {
    this.setState({ isInviteBuyerModalOpen: true });
  }

  onCloseBuyerModal() {
    this.setState({ isInviteBuyerModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = {
      ...urlQueryParams,
      ...this.state.currentQueryParams,
    };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, filterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
        };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      location,
      onManageDisableScrolling,
      pagination,
      activeSupplierId,
      addedToOrder,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      productCategories,
      pageHeader,
      currentUser,
      currentUserPermissions,
      providerNotificationCount,
      params,
      getListing,
      currentUserActiveBaskets,
      currentUserActiveBasketsDetails,
      activeListingId,
      onSendInvite,
      sendInviteError,
      sendInviteInProgress,
      sendInviteSuccess,
      inviteHasAlreadySentError,
      currentUserOrderCounts,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    if (productCategories) {
      const productCategoriesFromListings = [];

      // for each loop  to add all categories from the listing objects into one array
      listings.forEach(listing => {
        const category = listing.attributes?.publicData?.productCategory ?? '';

        if (productCategoriesFromListings.indexOf(category) === -1) {
          productCategoriesFromListings.push(category);
        }
      });

      var filteredProductCategories = productCategories.filter(cat =>
        productCategoriesFromListings.includes(cat.name)
      );
    }
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const availableFilters = isKeywordSearch
      ? filterConfig.filter(f => f.type !== 'KeywordFilter')
      : filterConfig;

    // Selected aka active filters
    const selectedFilters = validFilterParams(validQueryParams, filterConfig);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        validQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : { className: css.sortByDesktop };
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchInURL || {},
      intl
    );

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    const showActiveOrder =
      currentUserActiveBasketsDetails &&
      currentUserActiveBasketsDetails[activeSupplierId] &&
      currentUserActiveBasketsDetails[activeSupplierId]?.lineItems?.length > 0
        ? true
        : false;

    const activeOrderView = showActiveOrder ? (
      <ActiveOrder
        currentUserActiveBasketsDetails={currentUserActiveBasketsDetails[activeSupplierId]}
        addedToOrder={addedToOrder}
      />
    ) : null;

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const { tab } = params;

    const currentUserAttributes = currentUser ? currentUser.attributes : {};
    const ordersOnHold = currentUserAttributes.profile
      ? currentUserAttributes.profile.privateData.ordersOnHold || []
      : [];
    const onHoldOrdersCount = ordersOnHold ? ordersOnHold.length : null;
    const isOrders = tab === 'orders';
    const isSuppliers = tab === 'suppliers';
    const isCustomers = tab === 'customers';
    const isSales = tab === 'sales';
    const isCreateListing = tab === 'create-listing';
    let isYourListings = tab === 'your-listing';
    const isMarketPlace = tab === 'marketplace';
    const activeTab = tab;

    // START SIDE NAV
    const nav = (
      <SideNavigation
        currentUserPermissions={currentUserPermissions}
        ordersOnHold={ordersOnHold}
        isSuppliers={isSuppliers}
        isOrders={isOrders}
        isCustomers={isCustomers}
        isSales={isSales}
        providerNotificationCount={providerNotificationCount}
        isYourListings={isYourListings}
        setIsInviteSupplierModalOpen={this.onOpenSupplierModal}
        setIsInviteBuyerModalOpen={this.onOpenBuyerModal}
        orderCounts={currentUserOrderCounts}
      />
    );
    // END SIDE NAV

    const handleOrderSubmit = values => {
      this.handleSubmit(values);
    };
    const addUserModal = (
      <AddUserModal
        setIsInviteSupplierModalClosed={this.onCloseSupplierModal}
        isInviteSupplierModalOpen={this.state.isInviteSupplierModalOpen}
        setIsInviteBuyerModalClosed={this.onCloseBuyerModal}
        isInviteBuyerModalOpen={this.state.isInviteBuyerModalOpen}
        onManageDisableScrolling={onManageDisableScrolling}
        currentUser={currentUser}
        onSendInvite={onSendInvite}
        sendInviteInProgress={sendInviteInProgress}
        sendInviteError={sendInviteError}
        sendInviteSuccess={sendInviteSuccess}
        inviteHasAlreadySentError={inviteHasAlreadySentError}
      />
    );
    const modals = (
      <div>
        {/* <Modal
                {...props}
                isOpen={isInviteSupplierModalOpenFORM}
                onClose={() => {
                  setIsInviteSupplierModalOpen(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                id="inviteSupplier"
              >
                <InviteSupplierForm
                  className={css.form}
                  currentUser={currentUser}
                  // initialValues={{ firstName, lastName, bio, profileImage: user.profileImage }}
                  // profileImage={profileImage}
                  // onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
                  // uploadInProgress={uploadInProgress}
                  // updateInProgress={updateInProgress}
                  // uploadImageError={uploadImageError}
                  // updateProfileError={updateProfileError}
                  onSubmit={onSendInvite}
                />
              </Modal> */}
      </div>
    );

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage="SearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.layoutWrapperContainer}>
          <aside className={css.layoutWrapperFilterColumn} data-cy="asideSideNav">
            <div className={css.filterColumnContent}>
              <GoBackLink></GoBackLink>
              {nav}
            </div>
          </aside>

          <div className={css.layoutWrapperMain} role="main">
            <div className={css.searchResultContainer}>
              <SearchFiltersMobile
                className={css.searchFiltersMobileList}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onMapIconClick={onMapIconClick}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                isMapVariant={false}
                noResultsInfo={noResultsInfo}
              >
                {availableFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={validQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      liveEdit
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersMobile>
              {activeOrderView}
              <MainPanelHeader
                className={css.mainPanel}
                sortByComponent={sortBy('desktop')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                noResultsInfo={noResultsInfo}
                pageHeader={pageHeader}
              />
              <div
                className={classNames(css.listings, {
                  [css.newSearchInProgress]: !listingsAreLoaded,
                })}
              >
                {searchListingsError ? (
                  <h2 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </h2>
                ) : null}

                {!filteredProductCategories ? (
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={listings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    productCategories={filteredProductCategories}
                    isMapVariant={false}
                    onSubmit={handleOrderSubmit}
                    category={null}
                  />
                ) : null}

                {filteredProductCategories
                  ? filteredProductCategories.map(c => (
                      <SearchResultsPanelWithHeaders
                        className={css.searchListingsPanel}
                        listings={listings}
                        pagination={listingsAreLoaded ? pagination : null}
                        search={parse(location.search)}
                        productCategories={filteredProductCategories}
                        isMapVariant={false}
                        onSubmit={handleOrderSubmit}
                        category={c}
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {addUserModal}
        {modals}
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  activeSupplierId: null,
  addedToOrder: false,
  searchListingsError: null,
  searchParams: {},
  productCategories: null,
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  currentUser: null,
  currentUserPermissions: null,
  providerNotificationCount: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    activeSupplierId,
    addedToOrder,
    searchInProgress,
    searchListingsError,
    searchParams,
    productCategories,
    pageHeader,
  } = state.SearchPage;

  const pageListings = getListingsById(state, currentPageResultIds);
  const {
    currentUser,
    currentUserPermissions,

    providerNotificationCount,
    currentUserActiveBaskets,
    currentUserActiveBasketsDetails,
    sendInviteError,
    sendInviteInProgress,
    sendInviteSuccess,
    inviteHasAlreadySentError,
    currentUserOrderCounts,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    listings: pageListings,
    pagination,
    activeSupplierId,
    addedToOrder,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    productCategories,
    pageHeader,
    currentUser,
    currentUserPermissions,

    providerNotificationCount,
    currentUserActiveBaskets,
    currentUserActiveBasketsDetails,
    getListing,

    sendInviteError,
    sendInviteInProgress,
    sendInviteSuccess,
    inviteHasAlreadySentError,
    currentUserOrderCounts,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onSendInvite: body => {
    dispatch(sendInvite(body));
  },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
